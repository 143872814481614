


.fullstack{
    color: rgb(218, 54, 122);
}

.studnetsreadius img{
    border-radius: 29px;
}

.container{
    font-family: 'Chakra Petch', sans-serif;
font-family: 'Play', sans-serif;
}



a {
    text-decoration: none !important;
   
    
  }

  .buttondistributor{
    background-color: rgb(240, 28, 151); /* Green */
    border: none;
    color: white;
    padding: 9px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 14px;;
  }

  .textcolor{
 
    color: rgb(240, 28, 151);
    
  }


  .buttonsearch{
    background-color: rgb(240, 28, 151); /* Green */
    border: none;
    color: white;
    padding: 5px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 14px;;
  }

  
.button4 {
  background-color: white; 
  color: rgb(35, 36, 37); 
  border: 2px solid rgb(240, 28, 151);
}

.button4:hover {
  background-color: rgb(240, 28, 151);
  color: white;

}
  

.websitedevelopkrishnagar{
  background-color: rgb(232, 244, 255);
}


.coloradd h5{
  color: rgb(16, 103, 109);
}
