.bg {
    color: rgb(240, 28, 151);
}



.mrindiancoder{
    
    background-color: rgb(240, 28, 151);
    padding: 0%;
    color: rgb(19, 16, 16);
    text-decoration-color:  rgb(224, 78, 78);
}


.buttonnav{
    background-color: rgb(240, 28, 151); /* Green */
    border: none;
    color: white;
    padding: 9px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 14px;;
}
