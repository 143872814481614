.umastyle{
    
    background-color: rgb(240, 28, 151);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(240, 28, 151);
}


.codingacademy{
    
    background-color: rgb(4, 81, 117);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(240, 28, 151);
}

.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }

