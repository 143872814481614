
.buttoncontact{
    background-color: rgb(240, 28, 151); /* Green */
    border: none;
    color: white;
    padding: 6px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 14px;;
}


.button3 {
    background-color: white; 
    color: rgb(35, 36, 37); 
    border: 2px solid rgb(240, 28, 151);
  }
  
  .button3:hover {
    background-color: rgb(240, 28, 151);
    color: white;
  
  }